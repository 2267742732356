.textFjallaOne{
  font-family: Fjalla One;
}

.introFjallaOne{
  text-align: left !important;
  font-family: Fjalla One;
}

.centerFjallaOne{
  text-align: center !important;
  font-family: Fjalla One;
}

.leftFjallaOne{
  text-align: left !important;
  font-family: Fjalla One;
}

.a2 {
  color: #1133cc;
  text-decoration: none;
  background-color: transparent;
}


.mt-10 {
  margin-top: 10rem !important;
}

.mb-10 {
  margin-bottom: 10rem !important;
}

.mt-20 {
  margin-top: 20rem !important;
}

.mb-20 {
  margin-bottom: 20rem !important;
}

.mb-50 {
  margin-bottom: 50rem !important;
}

.mb-75 {
  margin-bottom: 75rem !important;
}

.mb-100 {
  margin-bottom: 100rem !important;
}



.btn2 {
  font-size: 1.2rem;
  font-family: Fjalla One !important;
    border: 10px;
  border-radius: 10rem;
font-weight: 900;
  padding: 1rem 2rem;

  -webkit-box-shadow: 0 0.1875rem 0.1875rem 0 rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0 0.1875rem 0.1875rem 0 rgba(0, 0, 0, 0.1) !important;
  border: 10px;
font-family: 'Fjalla One'
}

.btn-gradient-blue {
  -webkit-transition : 0.5s;
       -o-transition : 0.5s;
     -moz-transition : 0.5s;
          transition : 0.5s;
  background-image : -webkit-linear-gradient(50deg, #28BCFD 20%, #1D78FF 51%, #28BCFD 90%);
  background-image :    -moz-linear-gradient(50deg, #28BCFD 20%, #1D78FF 51%, #28BCFD 90%);
  background-image :      -o-linear-gradient(50deg, #28BCFD 20%, #1D78FF 51%, #28BCFD 90%);
  background-image :         linear-gradient(40deg, #28BCFD 20%, #1D78FF 51%, #28BCFD 90%);
  background-position : left center;
  -webkit-background-size : 200% auto;
          background-size : 200% auto;
  border : 0;
  color : #FFFFFF;
}

.btn-gradient-blue:hover, .btn-gradient-blue:active {
  background-position : right center;
  text-decoration : none;
  color : #FFFFFF;
}
.btn-gradient-blue:not(:disabled):not(.disabled):active {
  background-image : -webkit-linear-gradient(50deg, #28BCFD 0%, #1D78FF 51%, #28BCFD 90%);
  background-image :    -moz-linear-gradient(50deg, #28BCFD 0%, #1D78FF 51%, #28BCFD 90%);
  background-image :      -o-linear-gradient(50deg, #28BCFD 0%, #1D78FF 51%, #28BCFD 90%);
  background-image :         linear-gradient(40deg, #28BCFD 0%, #1D78FF 51%, #28BCFD 90%);
}
.btn-gradient-blue.btn-glow {
  -webkit-box-shadow : 0 0 12px 0 #1F87FF;
          box-shadow : 0 0 12px 0 #1F87FF;
}

.btn-gradient-orange {
  -webkit-transition : 0.5s;
       -o-transition : 0.5s;
     -moz-transition : 0.5s;
          transition : 0.5s;
  background-image : -webkit-linear-gradient(50deg, #FF4137 20%, #FDC800 51%, #FF4137 90%);
  background-image :    -moz-linear-gradient(50deg, #FF4137 20%, #FDC800 51%, #FF4137 90%);
  background-image :      -o-linear-gradient(50deg, #FF4137 20%, #FDC800 51%, #FF4137 90%);
  background-image :         linear-gradient(40deg, #FF4137 20%, #FDC800 51%, #FF4137 90%);
  background-position : left center;
  -webkit-background-size : 200% auto;
          background-size : 200% auto;
  border : 0;
  color : #FFFFFF;
}
.btn-gradient-orange:hover, .btn-gradient-orange:active {
  background-position : right center;
  text-decoration : none;
  color : #FFFFFF;
}
.btn-gradient-orange:not(:disabled):not(.disabled):active {
  background-image : -webkit-linear-gradient(50deg, #FF4137 0%, #FDC800 51%, #FF4137 90%);
  background-image :    -moz-linear-gradient(50deg, #FF4137 0%, #FDC800 51%, #FF4137 90%);
  background-image :      -o-linear-gradient(50deg, #FF4137 0%, #FDC800 51%, #FF4137 90%);
  background-image :         linear-gradient(40deg, #FF4137 0%, #FDC800 51%, #FF4137 90%);
}
.btn-gradient-orange.btn-glow {
  -webkit-box-shadow : 0 0 12px 0 #FF5F2B;
          box-shadow : 0 0 12px 0 #FF5F2B;
}

.btn-gradient-purple {
  -webkit-transition : 0.5s;
       -o-transition : 0.5s;
     -moz-transition : 0.5s;
          transition : 0.5s;
  background-image : -webkit-linear-gradient(50deg, #BF68E6 20%, #9E48CD 51%, #BF68E6 90%);
  background-image :    -moz-linear-gradient(50deg, #BF68E6 20%, #9E48CD 51%, #BF68E6 90%);
  background-image :      -o-linear-gradient(50deg, #BF68E6 20%, #9E48CD 51%, #BF68E6 90%);
  background-image :         linear-gradient(40deg, #BF68E6 20%, #9E48CD 51%, #BF68E6 90%);
  background-position : left center;
  -webkit-background-size : 200% auto;
          background-size : 200% auto;
  border : 0;
  color : #FFFFFF;
}
.btn-gradient-purple:hover, .btn-gradient-purple:active {
  background-position : right center;
  text-decoration : none;
  color : #FFFFFF;
}
.btn-gradient-purple:not(:disabled):not(.disabled):active {
  background-image : -webkit-linear-gradient(50deg, #BF68E6 0%, #9E48CD 51%, #BF68E6 90%);
  background-image :    -moz-linear-gradient(50deg, #BF68E6 0%, #9E48CD 51%, #BF68E6 90%);
  background-image :      -o-linear-gradient(50deg, #BF68E6 0%, #9E48CD 51%, #BF68E6 90%);
  background-image :         linear-gradient(40deg, #BF68E6 0%, #9E48CD 51%, #BF68E6 90%);
}
.btn-gradient-purple.btn-glow {
  -webkit-box-shadow : 0 0 12px 0 #9E48CD;
          box-shadow : 0 0 12px 0 #9E48CD;
}

.btn-light {
  color : #7D7D7D;
}




.bigtextwhite emwhite{
  font-size: 2.5rem;
  color: #ffffff;
}



.textdesc {
  color: #343a40;
  font-size: 1.25rem;
}

.textdesc emblack{
  color: #000000;
}

.textdesc emwhite{
  color: #fff;
}

.textdesc emgray{
  color: #868e96;
}

.textdesc emred {
  color: #d10000;
}

.textdesc emorange {
  color: #ff6622;
}

.textdesc emyellow {
  color: #ffda21;
}

.textdesc emgreen {
  color: #33dd00;
}

.textdesc emlime {
  color: #33dd00;
}

.textdesc emblue {
  color: #1133cc;
}

.textdesc emindigo {
  color: #220066;
}

.textdesc emviolet {
  color: #330044;
}



.xxsmtextdesc {
  color: #343a40;
  font-size: 1rem;
}

.xxsmtextdesc emblack{
  color: #000000;
}

.xxsmtextdesc emwhite{
  color: #fff;
}

.xxsmtextdesc emgray{
  color: #868e96;
}

.xxsmtextdesc emred {
  color: #d10000;
}

.xxsmtextdesc emorange {
  color: #ff6622;
}

.xxsmtextdesc emyellow {
  color: #ffda21;
}

.xxsmtextdesc emgreen {
  color: #33dd00;
}

.xxsmtextdesc emlime {
  color: #33dd00;
}

.xxsmtextdesc emblue {
  color: #1133cc;
}

.xxsmtextdesc emindigo {
  color: #220066;
}

.xxsmtextdesc emviolet {
  color: #330044;
}





.xsmtextdesc {
  color: #343a40;
  font-size: 1.2rem;
}

.xsmtextdesc emblack{
  color: #000000;
}

.xsmtextdesc emwhite{
  color: #fff;
}

.xsmtextdesc emgray{
  color: #868e96;
}

.xsmtextdesc emred {
  color: #d10000;
}

.xsmtextdesc emorange {
  color: #ff6622;
}

.xsmtextdesc emyellow {
  color: #ffda21;
}

.xsmtextdesc emgreen {
  color: #33dd00;
}

.xsmtextdesc emlime {
  color: #33dd00;
}

.xsmtextdesc emblue {
  color: #1133cc;
}

.xsmtextdesc emindigo {
  color: #220066;
}

.xsmtextdesc emviolet {
  color: #330044;
}


.smtextdesc {
  color: #343a40;
  font-size: 1.5rem;
}

.smtextdesc emblack{
  color: #000000;
}

.smtextdesc emwhite{
  color: #fff;
}

.smtextdesc emgray{
  color: #868e96;
}

.smtextdesc emred {
  color: #d10000;
}

.smtextdesc emorange {
  color: #ff6622;
}

.smtextdesc emyellow {
  color: #ffda21;
}

.smtextdesc emgreen {
  color: #33dd00;
}

.smtextdesc emlime {
  color: #33dd00;
}

.smtextdesc emblue {
  color: #1133cc;
}

.smtextdesc emindigo {
  color: #220066;
}

.smtextdesc emviolet {
  color: #330044;
}



.mdtextdesc {
  color: #343a40;
  font-size: 1.75rem;
}

.mdtextdesc emblack{
  color: #000000;
}

.mdtextdesc emwhite{
  color: #fff;
}

.mdtextdesc emgray{
  color: #868e96;
}

.mdtextdesc emred {
  color: #d10000;
}

.mdtextdesc emorange {
  color: #ff6622;
}

.mdtextdesc emyellow {
  color: #ffda21;
}

.mdtextdesc emgreen {
  color: #33dd00;
}

.mdtextdesc emlime {
  color: #33dd00;
}

.mdtextdesc emblue {
  color: #1133cc;
}

.mdtextdesc emindigo {
  color: #220066;
}

.mdtextdesc emviolet {
  color: #330044;
}



.bigtextdesc {
  color: #343a40;
  font-size: 2.5rem;
}

.bigtextdesc emblack{
  color: #000000;
}

.bigtextdesc emwhite{
  color: #fff;
}

.bigtextdesc emgray{
  color: #868e96;
}

.bigtextdesc emred {
  color: #d10000;
}

.bigtextdesc emorange {
  color: #ff6622;
}

.bigtextdesc emyellow {
  color: #ffda21;
}

.bigtextdesc emgreen {
  color: #33dd00;
}

.bigtextdesc emlime {
  color: #33dd00;
}

.bigtextdesc emblue {
  color: #1133cc;
}

.bigtextdesc emindigo {
  color: #220066;
}

.bigtextdesc emviolet {
  color: #330044;
}



.reallybigtextdesc {
  color: #343a40;
  font-size: 3.5rem;
}

.reallybigtextdesc emblack{
  color: #000000;
}

.reallybigtextdesc emwhite{
  color: #fff;
}

.reallybigtextdesc emgray{
  color: #868e96;
}

.reallybigtextdesc emred {
  color: #d10000;
}

.reallybigtextdesc emorange {
  color: #ff6622;
}

.reallybigtextdesc emyellow {
  color: #ffda21;
}

.reallybigtextdesc emgreen {
  color: #33dd00;
}

.reallybigtextdesc emlime {
  color: #33dd00;
}

.reallybigtextdesc emblue {
  color: #1133cc;
}

.reallybigtextdesc emindigo {
  color: #220066;
}

.reallybigtextdesc emviolet {
  color: #330044;
}







.xxsmtextdarkgray {
  font-size: 1em;
  color: #343a40;
}

.xxsmtextblack{
  font-size: 1em;
  color: #000000;
}

.xxsmtextwhite{
  font-size: 1em;
  color: #fff;
}

.xxsmtextgray{
  font-size: 1em;
  color: #868e96;
}

.xxsmtextred {
  font-size: 1em;
  color: #d10000;
}

.xxsmtextorange {
  font-size: 1em;
  color: #ff6622;
}

.xxsmtextyellow {
  font-size: 1em;
  color: #ffda21;
}

.xxsmtextgreen {
  font-size: 1em;
  color: #33dd00;
}

.xxsmtextblue {
  font-size: 1em;
  color: #1133cc;
}

.xxsmtextindigo {
  font-size: 1em;
  color: #220066;
}

.xxsmtextviolet {
  font-size: 1em;
  color: #330044;
}

.xxsmtextgold {
  font-size: 1em;
  color: #FFD700;
}

.xxsmtextcustom1 {
  font-size: 1em;
  color: #FFD700;
}

.xxsmtextcustom2 {
  font-size: 1em;
  color: #FFD700;
}


.xsmtextdarkgray {
  font-size: 1.2em;
  color: #343a40;
}

.xsmtextblack{
  font-size: 1.2em;
  color: #000000;
}

.xsmtextwhite{
  font-size: 1.2em;
  color: #fff;
}

.xsmtextgray{
  font-size: 1.2em;
  color: #868e96;
}

.xsmtextred {
  font-size: 1.2em;
  color: #d10000;
}

.xsmtextorange {
  font-size: 1.2em;
  color: #ff6622;
}

.xsmtextyellow {
  font-size: 1.2em;
  color: #ffda21;
}

.xsmtextgreen {
  font-size: 1.2em;
  color: #33dd00;
}

.xsmtextblue {
  font-size: 1.2em;
  color: #1133cc;
}

.xsmtextindigo {
  font-size: 1.2em;
  color: #220066;
}

.xsmtextviolet {
  font-size: 1.2em;
  color: #330044;
}

.xsmtextgold {
  font-size: 1.2em;
  color: #FFD700;
}

.xsmtextcustom1 {
  font-size: 1.2em;
  color: #FFD700;
}

.xsmtextcustom2 {
  font-size: 1.2em;
  color: #FFD700;
}






.smtextdarkgray {
  font-size: 1.5em;
  color: #343a40;
}

.smtextblack{
  font-size: 1.5em;
  color: #000000;
}

.smtextwhite{
  font-size: 1.5em;
  color: #fff;
}

.smtextgray{
  font-size: 1.5em;
  color: #868e96;
}

.smtextred {
  font-size: 1.5em;
  color: #d10000;
}

.smtextorange {
  font-size: 1.5em;
  color: #ff6622;
}

.smtextyellow {
  font-size: 1.5em;
  color: #ffda21;
}

.smtextgreen {
  font-size: 1.5em;
  color: #33dd00;
}

.smtextblue {
  font-size: 1.5em;
  color: #1133cc;
}

.smtextindigo {
  font-size: 1.5em;
  color: #220066;
}

.smtextviolet {
  font-size: 1.5em;
  color: #330044;
}

.smtextgold {
  font-size: 1.5em;
  color: #FFD700;
}

.smtextcustom1 {
  font-size: 1.5em;
  color: #FFD700;
}

.smtextcustom2 {
  font-size: 1.5em;
  color: #FFD700;
}



.mdtextdarkgray {
  font-size: 1.75rem;
  color: #343a40;
}

.mdtextblack{
  font-size: 1.75rem;
  color: #000000;
}

.mdtextwhite{
  font-size: 1.75rem;
  color: #fff;
}

.mdtextgray{
  font-size: 1.75rem;
  color: #868e96;
}

.mdtextred {
  font-size: 1.75rem;
  color: #d10000;
}

.mdtextorange {
  font-size: 1.75rem;
  color: #ff6622;
}

.mdtextyellow {
  font-size: 1.75rem;
  color: #ffda21;
}

.mdtextgreen {
  font-size: 1.75rem;
  color: #33dd00;
}

.mdtextblue {
  font-size: 1.75rem;
  color: #1133cc;
}

.mdtextindigo {
  font-size: 1.75rem;
  color: #220066;
}

.mdtextviolet {
  font-size: 1.75rem;
  color: #330044;
}

.mdtextgold {
  font-size: 1.75rem;
  color: #FFD700;
}

.mdtextcustom1 {
  font-size: 1.75rem;
  color: #FFD700;
}

.mdtextcustom2 {
  font-size: 1.75rem;
  color: #FFD700;
}


.bigtextdarkgray {
  font-size: 2.5rem;
  color: #343a40;
}

.bigtextblack{
  font-size: 2.5rem;
  color: #000000;
}

.bigtextwhite {
  font-size: 2.5rem;
  color: #fff;
}

.bigtext emgray{
  font-size: 2.5rem;
  color: #868e96;
}

.bigtextred {
  font-size: 2.5rem;
  color: #d10000;
}

.bigtextorange {
  font-size: 2.5rem;
  color: #f4623a;
}

.bigtextyellow {
  font-size: 2.5rem;
  color: #ffda21;
}

.bigtextgreen {
  font-size: 2.5rem;
  color: #33dd00;
}

.bigtextlime {
  font-size: 2.5rem;
  color: #3cff00;
}
.bigtextblue {
  font-size: 2.5rem;
  color: #1133cc;
}

.bigtextindigo {
  font-size: 2.5rem;
  color: #220066;
}

.bigtextviolet {
  font-size: 2.5rem;
  color: #330044;
}

.bigtextgold {
  font-size: 2.5rem;
  color: #FFD700;
}


.reallybigtextblack{
  font-size: 3.5rem;
  color: #000000;
}

.reallybigtextwhite{
  font-size: 3.5rem;
  color: #fff;
}

.reallybigtextgray{
  font-size: 3.5rem;
  color: #868e96;
}

.reallybigtextred {
  font-size: 3.5rem;
  color: #d10000;
}

.reallybigtextorange {
  font-size: 3.5rem;
  color: #ff6622;
}

.reallybigtextyellow {
  font-size: 3.5rem;
  color: #ffda21;
}

.reallybigtextgreen {
  font-size: 3.5rem;
  color: #33dd00;
}

.reallybigtextblue {
  font-size: 3.5rem;
  color: #1133cc;
}

.reallybigtextindigo {
  font-size: 3.5rem;
  color: #220066;
}

.reallybigtextviolet {
  font-size: 3.5rem;
  color: #330044;
}

.reallybigtextgold {
  font-size: 3.5rem;
  color: #FFD700;
}

.reallybigtextcustom1 {
  font-size: 3.5rem;
  color: #FFD700;
}

.reallybigtextcustom2 {
  font-size: 3.5rem;
  color: #FFD700;
}

/* div#tsparticles {
    position: relative;
} */

/* .container.HeroAnimation1{
    position: absolute;
    left: 25%;
    top: 26%;
} */

.container.HeroAnimation1 {
    position: absolute;
    top: 198px;
    left: 25%;
}